import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/clinic/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";

const DuplicateCustomerService = {
  fetchData: async (params: any, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(ApiRouterConstants.API_DUPLICATE_CUSTOMERS, { method: "GET", params: params }, http, {
      immediate: false,
      onSuccess: () => onSuccess(),
      onError: () => onError(),
    });
    return await execute();
  },
  fetchPreviewData: async (params: any, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(
      `${ApiRouterConstants.API_DUPLICATE_CUSTOMERS}/preview`,
      { method: "GET", params: params },
      http,
      {
        immediate: false,
        onSuccess: () => onSuccess(),
        onError: () => onError(),
      }
    );
    return await execute();
  },
  mergeData: async (params: any, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(`${ApiRouterConstants.API_CUSTOMERS}/merges`, { method: "POST", data: params }, http, {
      immediate: false,
      onSuccess: () => onSuccess(),
      onError: () => onError(),
    });
    return await execute();
  },
};

export default DuplicateCustomerService;
